<template>
  <div class="g_page_box">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <img src="../../assets/img/pay_cancel.png" class="icon" width="153">
      <div class="title">支付取消</div>
      <div class="surplus_time">剩余支付时间为 <van-count-down class="label" :time="data.left_time * 1000" @finish="finish" /></div>
      <div class="btn_2" @click="to_edit">修改订单</div>
    </div>
    <div class="bottom_box">
      <div class="btn" @click="to_pay">继续支付</div>
    </div>
  </div>
</template>

<script>
import { pay_detail } from '../pay/service'

export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.id,
      data: {}
    }
  },
  computed: {
  },
  components: {
  },
  created () {
    this.get_detail()
  },
  methods: {
    async get_detail () {
      this.loading = true
      try {
        const {data} = await pay_detail(this.id)
        if (data) {
          this.data = data
        }
      } finally {
        this.loading = false
      }
    },
    finish () {
      this.$router.replace({ path: '/my-order', query: {order_state: 5} })
    },
    to_edit () {
      this.$router.push({ path: '/pre-order', query: {id: this.id}})
    },
    to_pay () {
      this.$router.push({ path: '/pay', query: {id: this.id}})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  text-align: center;
}
.icon {
  margin-top: 65px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #242938;
  margin-top: 28px;
}
.surplus_time {
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  margin-top: 8px;
  & .label {
    color: #0088FF;
    display: inline;
    font-size: 12px;
  }
}
.btn_2 {
  border: 1px solid #B5B5B5;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 28px auto;
  font-size: 14px;
  color: #707070;
}
.bottom_box {
  padding: 24px 13px;
  & .btn {
    width: 100%;
    background: #0088FF;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>
